<template>
  <div class="test-check">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manager/test/list' }">考试阅卷</el-breadcrumb-item>
        <el-breadcrumb-item>考试详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="t-header">
        <span class="t-name">{{ testPaper.examName }}</span>
        <span>
          考生姓名：
          <font>{{ testPaper.studentName }}</font>
        </span>
        <span>
          答题时间：
          <font>{{ testPaper.studentExamDuration }}分钟</font>
        </span>
      </div>
      <div class="t-body">
        <div class="t-aside">
          <div class="t-text">答题卡</div>
          <div class="t-detail">
            <div
              class="t-list"
              v-for="(qTypeItem, qTypeIndex) in testPaper.questionTypes"
              :key="qTypeIndex"
            >
              <div class="t-type">
                {{ qTypeIndex + 1 }}.{{ typeConfig[qTypeItem.questionType] }}
                <font>共{{ qTypeItem.questionNum }}题，合计{{ qTypeItem.totalScore }}分</font>
              </div>
              <div class="t-wrap" >
                <div
				v-for="(item, questionIndex) in qTypeItem.correctFlagList"
                  :class="[
                    item == 'y' ? 't-right-item' : '',
                    item == 'n' ? 't-wrong-item' : '',
                    item == '' ? 't-item' : '',
                    item == null ? 't-item' : ''
                  ]"
                  @click="changeQues(qTypeItem.questionType)"
                >
                  {{ questionIndex + 1 }}
                </div>
              </div>
            </div>
          </div>
          <div class="t-tips">
            <div class="t-item">
              <span class="t-green"></span>
              <span class="t-right">正确</span>
            </div>
            <div class="t-item">
              <span class="t-red"></span>
              <span class="t-wrong">错误</span>
            </div>
          </div>
        </div>
        <div class="t-wrap">
          <div
            class="t-question"
            v-for="(questionItem, questionIndex) in testPaper.questionTypes"
            :key="questionIndex"
            v-if="questionItem.questionType === currentPage"
          >
            <div class="t-type">
              {{ typeConfig[questionItem.questionType] }}
              <font>共{{ questionItem.questionNum }}题，合计{{ questionItem.totalScore }}分</font>
            </div>
            <div
              class="t-item"
              v-for="(question, questionidx) in questionItem.questions"
              :key="questionidx"
			  style="margin-bottom: 20px;"
            >
              <div class="t-title"><font>{{ questionidx + 1 }}.</font><font style="display: inline-block;" v-html="question.questionDesc"></font></div>
              <div
                class="t-answer"
                v-show="question.questionType == 1 || question.questionType == 4"
              >
                <div
                  class="t-answer-item"
                  v-for="(optionItem, optionIndex) in question.options"
                  :key="optionIndex"
                >
                  <el-radio-group>
                    <el-radio class="t-radio" :label="1">
                      <span class="t-option">{{ optionItem.optionCode }}</span>
                      {{ optionItem.optionDesc }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div
                class="t-answer"
                v-show="question.questionType == 2 || question.questionType == 3"
              >
                <div
                  class="t-answer-item"
                  v-for="(optionItem, optionIndex) in question.options"
                  :key="optionItem.optionId"
                >
                  <el-checkbox class="t-radio">
                    <span class="t-option">{{ optionItem.optionCode }}</span>
                    {{ optionItem.optionDesc }}
                  </el-checkbox>
                </div>
              </div>
              <div
                class="t-questions"
                v-show="question.questionType == 5 || question.questionType == 6"
              ></div>
              <div class="t-tips">
                <span>
                  考生答案:
                  <span v-for="(item, index) in question.studentAnswer" :key="index">
                    {{ item }}
                  </span>
                </span>
                <br />
                <span>
                  正确答案:
                  <span v-for="(item, index) in question.correctAnswers" :key="index">
                    {{ item }}
                  </span>
                </span>
                <br />
                <span>
                  解释说明:
                  <span style="display: inline-block;" v-html="question.questionAnls"></span>
                </span>
              </div>
              <div class="t-btns">
                <el-button class="t-right" v-if="question.correctFlag == 'y'">正确</el-button>
                <el-button class="t-wrong" v-else>错误</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="t-page-wrap">
        <div class="t-page">
          <span :class="havePre ? '' : 't-limit'"v-if="havePre" @click="prePage">上一页</span>
          <span :class="haveNext ? '' : 't-limit'" v-if="haveNext" @click="nextPage">下一页</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  data() {
    return {
      testPaper: {},
      haveNext: true,
      havePre: true,
      examId: this.$route.query.examId,
      studentId: this.$route.query.studentId,
      currentPage: 1,
      totalQuestionTypeNum: 0,
      typeConfig: {
        1: '单选题',
        2: '多选题',
        3: '不定项选择题',
        4: '判断题',
        5: '填空题',
        6: '问答题'
      },
      studentTestId: ''
    };
  },
  computed: {},
  created() {
    this.gettestPaper();
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取考试卷子
    gettestPaper() {
      this.post(
        `/biz/teacher/qb/studentExamDetail`,
        { examId: this.examId, studentId: this.studentId },
        e => {
          if (e.code == 200) {
            this.studentTestId = e.data.studentTestId;
            this.testPaper = e.data;
            this.totalQuestionTypeNum = this.testPaper.questionTypes.length;
            // 题目类型
            this.questionTypes = this.testPaper.questionTypes;
            console.log(this.testPaper);
          } else {
            this.$.ui.toast(e.msg);
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          }
        }
      );
    },
    // 更换题目类型
    changeQues(questionType) {
      this.currentPage = questionType;
      if (this.currentPage === this.totalQuestionTypeNum) {
        this.haveNext = false;
        this.havePre = true;
      } else if (this.currentPage === 1) {
        this.havePre = false;
        this.haveNext = true;
      } else {
        this.havePre = true;
        this.haveNext = true;
      }
    },
    prePage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.haveNext = true;
      }
      if (this.currentPage === 1) {
        this.havePre = false;
      }
    },
    nextPage() {
      if (this.currentPage === this.totalQuestionTypeNum - 1) {
        this.haveNext = false;
      }
      if (this.currentPage < this.totalQuestionTypeNum) {
        this.currentPage++;
        this.havePre = true;
      }
    }
  },
  components: {}
};
</script>

<style lang="less"></style>
